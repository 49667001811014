import React, { useEffect } from 'react'
import { useState } from 'react'
import db from '../../../firebase';
import { Helmet } from 'react-helmet';
import Layout from "../../../layout/dark-default"
import SectionMastHead from "../../../components/organism/product/section-masthead"
import SectionSlider from "../../../components/organism/product/section-slider"
import SectionProduct from "../../../components/organism/product/section-product"
import { getDownloadURL, getStorage, get, ref } from "firebase/storage"
import SectionProducts from "../../../components/organism/product/section-products2"

const Index = ({ id }) => {
    const [product, setProduct] = useState([]);
    const [sliderImageUrl, setSliderImageUrl] = useState([]);
    const [relatedSubProduct, setRelatedSubProduct] = useState([]);
    const [subProduct, setSubProduct] = useState([]);
    const storage = getStorage();
    let SlugFilter;

    useEffect(() => {
        db.collection('Product').onSnapshot(snapshot => {
            setProduct(snapshot.docs.map(doc => ({
                ID: doc.id,
                Description: doc.data().Description,
                canonical_url: doc.data().canonical_url,
                categories: doc.data().categories,
                meta_desc: doc.data().meta_desc,
                meta_title: doc.data().meta_title,
                parent_productslug: doc.data().parent_productslug,
                slug: doc.data().slug,
                subcategory_product_banner: doc.data().subcategory_product_banner,
                subcategory_product_card_img: doc.data().subcategory_product_card_img,
                subcategory_product_card_title: doc.data().subcategory_product_card_title,
                subcategory_product_img: doc.data().subcategory_product_img,
                subcategory_product_link: doc.data().subcategory_product_link,
                product_slider_img: doc.data().product_slider_img,
                related_subproducts: doc.data().related_subproducts,
                title: doc.data().title
            })))
        })
    }, [id])

    SlugFilter = product.filter(p => p.slug == id);
    useEffect(() => {
        setSliderImageUrl([]);
        setSubProduct([]);
        setRelatedSubProduct([])
        SlugFilter[0] && SlugFilter[0].product_slider_img.map(image => {
            if (typeof image === "string") {
                const imagaRef = ref(storage, image);
                // setTimeout(() => {
                getDownloadURL(imagaRef).then(url => {
                    setSliderImageUrl((prev) => [...prev, url]);
                })
                // },);
            }
        })
        // setTimeout(() => {
        if (SlugFilter[0] && SlugFilter[0].subcategory_product_img) {
            SlugFilter[0].subcategory_product_img.map(image => {
                // if (typeof image === "string") {
                const imagaRef = ref(storage, image);
                // setTimeout(() => {
                getDownloadURL(imagaRef).then(url => {
                    setSubProduct((prev) => [...prev, url]);
                })
                // },);
                // }
            })
        }
        // }, 1000)


        if (SlugFilter[0] && SlugFilter[0].related_subproducts) {
            SlugFilter[0].related_subproducts.map(p => {
                var docRef = db.collection("Product").doc(p.id);
                docRef.get().then((doc) => {
                    if (doc.exists) {
                        setRelatedSubProduct(prev => [...prev, doc.data()]);
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });
            })
        }

    }, [product])

    console.log(product, "product");


    return (
        <>
            <Helmet>
                {SlugFilter ? <title>{SlugFilter[0] && `${SlugFilter[0].title} - Glastone Mosaic`}</title> : <title>{SlugFilter[0] && SlugFilter[0].meta_title}</title>}
                {SlugFilter ? <meta name="description" content={SlugFilter[0] && SlugFilter[0].meta_desc} /> : null}
                {SlugFilter ? <meta name="keywords" content={SlugFilter[0] && SlugFilter[0].metakeywords} /> : null}
                <link rel="canonical" href={SlugFilter[0] && SlugFilter[0].canonical_url} />
            </Helmet>

            <Layout>
                {product && <SectionMastHead title={SlugFilter[0] && SlugFilter[0].title.replace("&nbsp;", " ")} desc={SlugFilter[0] && SlugFilter[0].Description} subprod={SlugFilter[0] && SlugFilter[0].sliderImg && SlugFilter[0].subCategory_product_data || SlugFilter[0] && SlugFilter[0].product_slider_img || SlugFilter[0] && SlugFilter[0].product_slider_img ? false : "section_sub_pb"} />}

                {/* slider added but issue in com- client width / */}
                <SectionSlider imgUrl={sliderImageUrl} />

                {/* err using of documentreference to pass com' as props & use it */}
                {relatedSubProduct && relatedSubProduct.map((data, index) => (
                    <SectionProduct data={data} key={product.slug + index} />
                ))}

                {subProduct && <SectionProducts technical={false} products={subProduct} />}
                {product.technical ? <SectionProducts technical products={product.technical} /> : null}
            </Layout>
        </>
    )
}

export default Index